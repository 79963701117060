<template>
  <div class="admin-wizard">
    <AdminHeader />
    <AdminBreadcrumb />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9" class="manage-endorsements-wrapper">
            <!-- Info Block -->
            <b-card class="mb-4">
              <b-card-body class="p-0">
                <b-card-title>
                  Manage which endorsements appear on your website.
                </b-card-title>
                <div class="guide-tip">
                  <b-row align-v="center">
                    <b-col cols="3" lg="1" class="icon">
                      <font-awesome-icon :icon="['fas', 'users']" />
                    </b-col>
                    <b-col cols="12" lg="8" order="3" order-lg="2" class="text">
                      <div>
                        Now that you have endorsements you can choose to show
                        them on your site. You can toggle which endorsements you
                        would to feature first and you can update the visibility
                        of each endorsement individually.
                      </div>
                      <div class="mt-2" v-if="candidate.isPetition">
                        As a petition candidate, you can track the number of
                        signatures you received on the next step in this wizard.
                      </div>
                    </b-col>
                    <b-col cols="9" lg="3" order="2" order-lg="3" class="button">
                      <b-button variant="outline-secondary" size="sm" v-b-modal.modal-theme-guide>
                        View Example
                        <font-awesome-icon :icon="['far', 'window-maximize']" />
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
                <div v-if="showStatementDropdown" class="mt-5 mb-3 multiple-endorsements">
                  <b-row>
                    <b-col lg="3">
                      <img src="../../assets/images/admin/endorsements/multiple-endorsements.svg" class="img-fluid" />
                    </b-col>
                    <b-col lg="9" class="align-self-center">
                      <h5 class="font-weight-normal">
                        You have multiple Non-Candidate Statements.
                      </h5>
                      <p class="text-muted">
                        Select which statement you would like to manage
                        endorsements for below.
                      </p>
                      <b-form inline>
                        <label class="mr-3 font-weight-bold">Select Endorsement Statement</label>
                        <b-form-select v-model="statementSelected" :options="statementOptions"></b-form-select>
                      </b-form>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>
            </b-card>

            <!-- No Featured Endorsements -->
            <b-card v-show="!hasFeaturedEndorsements" class="mb-4 no-featured">
              <b-card-body class="p-0">
                <b-card-title>
                  Your featured endorsements.
                  <span class="font-weight-normal">(Optional)</span>
                </b-card-title>
                <b-row>
                  <b-col lg="9">
                    <h5 class="font-weight-normal mb-3">
                      You currently have no featured endorsements. To feature an
                      endorsement, toggle the "Featured" switch on below.
                    </h5>
                    <p class="text-muted">
                      You can edit your featured endorsements list here after
                      you add a few. Compare how your endorsements with be
                      displayed without featuring endorsements versus featuring
                      endorsements
                      <b-link v-b-modal.modal-endorsement-example>View Example</b-link>
                    </p>
                  </b-col>
                  <b-col lg="3">
                    <img src="../../assets/images/admin/endorsements/no_featured.svg" class="img-fluid" />
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>

            <!-- Featured Endorsements -->
            <b-card v-show="hasFeaturedEndorsements" class="mb-4">
              <b-card-body class="p-0">
                <b-card-title> Your featured endorsements. </b-card-title>
                <b-row>
                  <b-col>
                    <b-tabs v-model="tabIndex" content-class="mt-3">
                      <b-tab title="Layout" active>
                        <LayoutTab @next="tabIndex++" :selectedLayout="selectedLayout" @select="getSelectedLayout">
                        </LayoutTab>
                      </b-tab>
                      <b-tab title="Organize">
                        <OrganizeTab2 @prev="tabIndex--" @next="next" :statementId="statementSelected"></OrganizeTab2>
                      </b-tab>
                      <b-tab title="Preview">
                        <PreviewTab @prev="tabIndex--" @save="save" :statementId="statementSelected"></PreviewTab>
                      </b-tab>
                    </b-tabs>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>

            <!-- List of All Endorsements -->
            <b-card class="mb-4" v-if="candidateModel.manageEndorsementList.length > 0">
              <b-card-body class="p-0">
                <b-card-title> List of all your endorsements. </b-card-title>
                <div class="manage-endorsements">
                  <FullEndorsementList :candidate="candidate" :statementId="statementSelected" />
                </div>
                <b-alert class="mt-4" variant="primary" :show="hasSubmittedItems">
                  <span class="font-weight-bold">Note: </span>When saving this
                  page your changes will be displayed on your website
                  immediately.
                </b-alert>
              </b-card-body>
            </b-card>

            <!-- <AdminFooterButton            
              @on-back="onBack"                            
              :hide-skip-button="!candidate.isPetition"
              :save-text-button="saveText"
              :saving="saving"
              :invalid="false"
              :hide-save-button="!hasSubmittedItems"
              :hide-dashboard-button="!showDashboardButton"
            /> -->
          </b-col>
        </b-row>
      </b-container>
    </main>
    <ModalThemeGuide theme-id="endorsementList" :template-id="candidateModel.profileTheme" />
    <ModalEndorsementExample />
  </div>
</template>
<script>
import CandidateMixin from "@/mixins/candidate.mixin";
import LayoutTab from "@/components/admin/ManageEndorsements/LayoutTab.vue";
import OrganizeTab2 from "@/components/admin/ManageEndorsements/OrganizeTab2.vue";
import PreviewTab from "@/components/admin/ManageEndorsements/PreviewTab.vue";
import ModalEndorsementExample from "@/components/admin/ModalEndorsementExample.vue";
export default {
  name: "ManageEndorsements",
  mixins: [CandidateMixin],
  async mounted() {
    await this.$store.dispatch(
      "candidates/getCandidate",
      this.$route.params.id
    );
    await this.getEndorsementStatements(this.candidateModel);
    await this.getFeaturedEndorsements(this.candidateModel);
  },
  data() {
    return {
      statementSelected: null,
      statementOptions: [],
      showStatementDropdown: true,
      tabIndex: 1,
      selectedLayout: "left",
      endorsements: {},
      hasFeaturedEndorsements: false,
      featuredEndorsements: [],

    };
  },
  watch: {
    endorsements() {
      return this.endorsements;
    },
    candidate: function (newVal) {
      this.getFeaturedEndorsements(newVal);
    },
    statementSelected: async function (newVal) {
      this.getFeaturedEndorsements(this.candidate);
    },
  },
  methods: {
    onSave(event) {
      this.$emit("on-save");
    },
    save() {
      const payload = this.endorsements.listGroups;
    },
    getSelectedLayout(val) {
      this.selectedLayout =
        this.candidateModel.endorsementLayout == 0 ? "left" : "center";
    },
    updateGroup(data) {
      if (data) {
        this.endorsements = data;
      }
    },
    next() {
      this.tabIndex++;
    },

    async getEndorsementStatements(candidate) {
      let data = candidate.publishedEndorsementStatements.map((item, index) => {
        const id = item.id;
        const number = item.number;
        return {
          value: id,
          text: number,
        };
      });
      this.statementOptions = data;
      if (data.length > 0) {
        this.statementSelected = data[0].value;
      }
      if (candidate.publishedEndorsementStatements.length > 1) {
        this.showStatementDropdown = true;
      } else {
        this.showStatementDropdown = false;
      }
    },
    async getFeaturedEndorsements(candidate) {
      var statementId = this.statementSelected;
      var featuredEndorsements = candidate.manageEndorsementList.filter(
        function (item) {
          return (
            item.isFeatured == true &&
            item.endorsementStatementId == statementId
          );
        }
      );
      this.featuredEndorsements = featuredEndorsements;
      if (featuredEndorsements.length > 0) {
        this.hasFeaturedEndorsements = true;
      } else {
        this.hasFeaturedEndorsements = false;
      }
    },
  },
  computed: {
    candidate() {
      return this.candidateModel;
    },
    allEndorsements() {
      return this.candidateModel.endorsements;
    },
  },
  components: { LayoutTab, OrganizeTab2, PreviewTab, ModalEndorsementExample },
};
</script>

<style lang="scss">
@import "@/assets/styles/admin/_base.scss";

.manage-endorsements-wrapper {
  span.key-color {
    width: 15px;
    height: 15px;
    display: inline-block;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 8px;
  }

  .key-orange {
    background-color: #f2903b;
  }

  .key-red {
    background-color: #e1585b;
  }

  .key-green {
    background-color: #5aa255;
  }

  .key-yellow {
    background-color: #eeca58;
  }

  .key-purple {
    background-color: #b07aa1;
  }

  .no-hover:hover,
  .no-hover:active {
    background: none !important;
  }

  .no-border-card,
  .no-hover {
    border: none !important;
  }

  .custom-card {
    margin-left: auto;
    margin-right: auto;
  }

  .list-group-item {
    cursor: grab;
  }

  .custom-icon {
    margin-left: 5px;
  }

  .custom-text {
    color: black;
    opacity: 0.8;
  }

  .custom-icon {
    font-weight: bold !important;
    cursor: pointer;
  }

  @media (max-width: 991px) {
    .multiple-endorsements img {
      width: 60%;
      margin-bottom: 40px;
    }

    .no-featured img {
      width: 60%;
      margin-top: 40px;
    }
  }
}
</style>
